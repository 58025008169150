

















































































































































































































































































































import {
  BCard,
  BRow,
  BCol,
  BForm,
  BPagination,
  BTable,
  BModal,
  BFormGroup,
  BFormInvalidFeedback,
  BFormDatepicker,
  BListGroup,
  BListGroupItem,
  BProgressBar,
  BProgress,
} from "bootstrap-vue";
import { AvButton, AvExportButton } from "@/components";
import { AvSkeletonTable } from "@/components/av-skeleton";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { Component, Vue } from "vue-property-decorator";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// Services
import { useOldService } from "@/services";

// Interfaces
import {
  IFile,
  IImportType,
  IResponseGetImportEmplacamento,
  IResponseUploadFile,
} from "@core/services/interfaces/oldService/IOldService";
import { IOption } from "@core/services/interfaces/IUtil";

interface IItem {
  Control_DatInc: string;
  Control_DatAlter: string;
  Tipo: string;
  Ime_Registros: number;
  Arquivo: string;
}

@Component({
  name: "ImportEmplacamento",
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BPagination,
    BTable,
    AvButton,
    AvExportButton,
    AvSkeletonTable,
    vSelect,
    BModal,
    BFormDatepicker,
    BListGroup,
    BListGroupItem,
    BProgressBar,
    BProgress,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
})
export default class ImportEmplacamento extends Vue {
  // Data
  $refs = {
    fileInput: {} as HTMLInputElement,
    refListTable: {} as any,
  };
  tableColumns = [
    { key: "Control_DatInc", label: "Data de Importação", sortable: false },
    { key: "Control_DatAlter", label: "Data de alteração", sortable: false },
    { key: "Tipo", label: "Tipo", sortable: true },
    { key: "Ime_Registros", label: "Registros", sortable: true },
    { key: "Arquivo", label: "Arquivo", sortable: true },
  ];
  formValidation = formValidation();
  modalAddShow: boolean = false;
  loading: boolean = false;
  saving: boolean = false;
  uploading = {
    status: false,
    fileName: "",
    progress: 0,
  };
  importTypeOptions: IOption[] = [];
  perPage = 10;
  pageOptions: number[] = [10, 25, 50, 100];
  totalRows = 1;
  currentPage = 1;
  sortBy = "";
  sortDesc = false;
  items: IItem[] = [];
  dateImport: string = "";
  importType: null | number = null;
  importFiles: IFile[] = [];

  // Computeds
  get dataMeta(): {
    from: number;
    to: number;
    of: number;
    total: number;
  } {
    const localItemsCount = this.items.length;
    return {
      from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
      to: this.perPage * (this.currentPage - 1) + localItemsCount,
      of: this.perPage,
      total: this.items.length,
    };
  }

  // LifeCycle
  created() {
    this.fetchListImports();
  }

  // Methods
  handlerSidebarAdd() {
    this.modalAddShow = true;
  }

  async onSubmit() {
    // @ts-ignore
    const result = await this.$refs.refFormObserver.validate();

    if (result) {
      this.saving = true;
      useOldService
        .requestCreateImportEmplacamento(
          this.importFiles.map((importFile) => {
            return {
              Fil_Guid: importFile.displayName,
              Fil_Nome: importFile.name,
              Fil_Tamanho: importFile.size,
              Ime_TipoImportacao: this.importType || -1,
              Ime_DataImportacao: this.dateImport,
              Ime_DataUltimaModificacao: this.dateImport,
            };
          })
        )
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Solicitação de importação criada com sucesso!",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.modalAddShow = false;
          this.fetchListImports();
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Erro ao tentar solicitar a importação de emplacamento!",
              icon: "AlertTriangleIcon",
              variant: "warning",
            },
          });
        })
        .finally(() => {
          this.saving = false;
        });
    } else {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Preencha todos os campos obrigatórios!",
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    }
  }

  fetchListImports() {
    this.loading = true;
    useOldService
      .requestGetImportEmplacamento()
      .then((response: IResponseGetImportEmplacamento) => {
        this.importTypeOptions = response.data.Ime_TipoImportacaoList.map(
          (type: IImportType) => ({
            label: type.Value,
            value: type.Key,
          })
        );

        const files = response.data.Fil_FileList;
        const data = response.data.data.slice().reverse();

        this.items = data.map((file) => {
          return {
            Control_DatInc: file.Ime_DataImportacao,
            Control_DatAlter: file.Ime_DataUltimaModificacao,
            Tipo:
              this.importTypeOptions?.find(
                (type) => type.value === file.Ime_TipoImportacao
              )?.label || "",
            Ime_Registros: file.Ime_Registros,
            Arquivo:
              files.find((archive) => archive.Fil_File_Id == file.Fil_File_Id)
                ?.Fil_Nome || "",
          };
        });
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar dados de importação de emplacamento!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  convertDatePtBr = (value: string): string => {
    const date = value.split("T")[0].split("-");

    return `${date[2]}/${date[1]}/${date[0]}`;
  };

  /**
   * Mapeando o valor do select status de um objecto <{label, value}> para apenas value boolean
   */
  reduceValueStatus = (val: { label: string; value: boolean }): boolean => {
    return val.value;
  };

  openAddFile() {
    const fileInput = this.$refs.fileInput;
    fileInput.dispatchEvent(new MouseEvent("click"));
  }

  handleFileInputChange(event: Event) {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      this.uploading.fileName = file.name;
      this.uploading.status = true;
      this.uploading.progress = 0;
      useOldService
        .requestUploadFile(file, {
          onUploadProgress: (progressEvent) => {
            this.uploading.progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        })
        .then((response: IResponseUploadFile) => {
          this.importFiles = this.importFiles.concat(response.data.files);
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Falha ao fazer upload do arquivo",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.$refs.fileInput.value = "";
          this.uploading.fileName = "";
          this.uploading.status = false;
          this.uploading.progress = 0;
        });
    }
  }

  deleteFile(fileName: string) {
    useOldService
      .requestDeleteFile(fileName)
      .then(() => {
        this.importFiles = this.importFiles.filter(
          (file) => file.name != fileName
        );
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Falha ao deletar arquivo",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.$refs.fileInput.value = "";
      });
  }

  getImports() {
    return new Promise((resolve, reject) => {
      try {
        const data = this.$refs.refListTable.filteredItems.map(
          (item: IItem) => ({
            "Data de importação": item.Control_DatInc
              ? this.convertDatePtBr(item.Control_DatInc)
              : "",
            "Data de alteração": item.Control_DatAlter
              ? this.convertDatePtBr(item.Control_DatAlter)
              : "",
            Tipo: item.Tipo,
            Registros: item.Ime_Registros,
            Arquivo: item.Arquivo,
          })
        );

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }
}
